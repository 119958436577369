import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import each from 'lodash/each';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { cloneDeep } from 'lodash';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import FormContainerAbstract from 'components/FormContainerAbstract';
import SubmitOrDiscardButtonsContainer from 'components/Form/SubmitOrDiscardButtonsContainer';
import App from 'modules/App';
import Table from 'components/Table';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import messages from '../../../../messages';
import * as constants from '../../../../constants';
import validatorRules from './validatorRules.json';
import styles from './UnitsOfMeasurements.pcss';


class UnitsOfMeasurementsForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    isFetchInProgress : PropTypes.bool.isRequired,
    // Explicit actions
    onClose           : PropTypes.func,
    // Implicit props
    isUpdateInProgress: PropTypes.bool.isRequired,
  };


  constructor(props) {
    super(props);
    this.state = {
      formInitialState : cloneDeep(this.props.unitMeasurements),
      hasUnsavedChanges: false,
      shouldReset      : false,
    };
    this.props.onSetFormValues(this.props.unitMeasurements);
    this.validatorRules = { ...validatorRules };
  }


  componentDidUpdate(prevProps) {
    this.setFormStatus();
    if (prevProps.isUpdateInProgress && !this.props.isUpdateInProgress) {
      this.onSetShouldReset();
    }
    if (this.state.shouldReset && !this.props.isFetchInProgress) {
      this.onInitialize();
    }
  }


  onSetShouldReset() {
    this.setState({ shouldReset: true });
  }

  onInitialize() {
    this.setState(
      {
        formInitialState : JSON.parse(JSON.stringify(this.props.unitMeasurements)),
        hasUnsavedChanges: false,
        shouldReset      : false,
      },
    );
    this.props.onSetFormValues(this.props.unitMeasurements);
  }


  onReset() {
    this.props.onSetFormValues(this.props.unitMeasurements);
    this.props.onFormErrors(null);
  }


  setFormStatus() {
    const hasUnsavedChanges = !isEqual(this.state.formInitialState, this.props.formValues.values);
    if (this.state.hasUnsavedChanges !== hasUnsavedChanges) {
      this.setState({ hasUnsavedChanges });
      this.props.onHasUnsavedChangesChanged(hasUnsavedChanges);
    }
  }


  get schema() {
    return [
      {
        key         : 'metricName',
        labelMessage: messages.labels.metricName,
        renderer    : (measurement) => (
          <b>
            <FormattedMessage {...messages.unitsOfMeasurements.names[measurement.id]} />
          </b>
        ),
      },
      {
        key         : 'unit',
        labelMessage: messages.labels.metricUnit,
        renderer    : (measurement) => this.renderOptions(measurement),
      },
    ];
  }


  get entities() {
    const result = [];
    each(constants.UNITS_OF_MEASUREMENTS_OPTIONS, (value, key) => {
      result.push(
        {
          id     : key,
          value  : this.props.unitMeasurements[key],
          options: value,
        },
      );
    });
    return result;
  }


  renderOptions(measurement) {
    return (
      <div className={styles.measurement__options}>
        {
          measurement.options.map((option) => (
            <FormGroup
              id={measurement.id}
              key={`${measurement.id}-${option}`}
              className="mb-0"
              formValues={this.props.formValues}
            >
              <CheckboxRadio
                inputValue={option}
                labelMessage={constants.UNITS_SYMBOLS[option]}
                value={measurement.value}
                className={styles.measurement__options__value}
                isRadio
                onChange={(input) => this.onSetValue(input)}
              />
            </FormGroup>
          ))
        }

      </div>

    );
  }


  renderTable() {
    return (
      <Table
        idKey="id"
        schema={this.schema}
        entities={this.entities}
        perPage={25}
        isPerPageOff
      />
    );
  }


  render() {
    return (
      <Form
        onSubmit={() => this.onSubmit()}
        onReset={() => this.onReset()}
      >
        { this.renderTable() }
        <SubmitOrDiscardButtonsContainer
          isSaveEnable={this.state.hasUnsavedChanges}
          isDiscardEnable={this.state.hasUnsavedChanges}
          isInProgress={this.props.isUpdateInProgress || this.props.isFetchInProgress}
          additionalInfoMessage={
            this.props.isConfiguredCountryActive
              ? <p><FormattedMessage {...messages.infos.saveOnActiveCountry} /></p>
              : null
          }
        />
      </Form>
    );
  }

}

const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(
    constants.CONFIGURE_COUNTRY_UNITS_OF_MEASUREMENTS_TAB_FORM,
  )(state),
  unitMeasurements         : selectors.unitMeasurements(state),
  isUpdateInProgress       : selectors.isUpdateCountryConfigurationUnitOfMeasurementInProgress(state),
  isConfiguredCountryActive: selectors.isConfiguredCountryActive(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.CONFIGURE_COUNTRY_UNITS_OF_MEASUREMENTS_TAB_FORM;
  return {
    onSubmit        : (values) => dispatch(actions.updateCountryConfigurationUnitsOfMeasurements(values)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedUnitsOfMeasurementsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnitsOfMeasurementsForm);


export default withStyles(styles)(ConnectedUnitsOfMeasurementsForm);
