import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import Form from 'components/Form';
import FormContainerAbstract from 'components/FormContainerAbstract';
import Table from 'components/Table';
import SubmitOrDiscardButtonsContainer from 'components/Form/SubmitOrDiscardButtonsContainer';
import DeleteIcon from 'svg/delete.svg';
import Loupe from 'svg/loupe-light.svg';
import AddIcon from 'svg/add.svg';
import App from 'modules/App';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import messages from '../../../../messages';
import * as constants from '../../../../constants';
import AddPayerModal from './AddPayerModal';
import ConfirmDeletePayerModal from './ConfirmDeletePayerModal';
import validatorRules from './validatorRules.json';
import styles from './Payers.pcss';


class PayersForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    isFetchInProgress      : PropTypes.bool.isRequired,
    // Explicit actions
    onOpenAddPayerModal    : PropTypes.func,
    onCloseAddPayerModal   : PropTypes.func,
    onOpenDeletePayerModal : PropTypes.func,
    onCloseDeletePayerModal: PropTypes.func,
    // Implicit props
    configurationPayers    : PropTypes.object,
  };


  constructor(props) {
    super(props);
    this.state = {
      formInitialState      : JSON.parse(JSON.stringify(this.props.configurationPayers)),
      hasUnsavedChanges     : false,
      payers                : [],
      search                : '',
      confirmDeletePayerName: null,
      shouldReset           : false,
    };
    this.validatorRules = validatorRules;
    this.props.onSetFormValues(this.props.configurationPayers);
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isUpdateInProgress && !this.props.isUpdateInProgress) {
      this.onSetShouldReset();
    }
    if (this.state.shouldReset && !this.props.isFetchInProgress) {
      this.onInitialize();
    }
    this.setFormStatus();
  }


  onSetShouldReset() {
    this.setState({ shouldReset: true });
  }


  onSubmit() {
    this.props.onSetFormValue({
      id   : 'names',
      value: this.payers,
    });
    super.onSubmit();
  }

  onInitialize() {
    this.setState({
      formInitialState      : JSON.parse(JSON.stringify(this.props.configurationPayers)),
      hasUnsavedChanges     : false,
      search                : '',
      confirmDeletePayerName: null,
      shouldReset           : false,
    });
    this.props.onSetFormValues({ ...this.props.configurationPayers });
  }


  onReset() {
    this.props.onSetFormValues({ ...this.props.configurationPayers });
    this.props.onFormErrors(null);
  }


  onClickDelete(payer) {
    this.setState({ confirmDeletePayerName: payer });
    this.props.onOpenDeletePayerModal();
  }


  onDeletePayer() {
    this.props.onSetFormValue({
      id   : 'names',
      value: filter(this.payers, (payer) => payer !== this.state.confirmDeletePayerName),
    });
    this.props.onCloseDeletePayerModal();
  }


  onAddPayer(payer) {
    this.props.onSetFormValue({
      id   : 'names',
      value: [...this.payers, payer.name],
    });
    this.props.onCloseAddPayerModal();
  }

  setFormStatus() {
    const hasUnsavedChanges = !isEqual(
      get(this.state.formInitialState, 'names', []).sort(),
      this.payers.sort(),
    );

    if (this.state.hasUnsavedChanges !== hasUnsavedChanges) {
      this.setState({ hasUnsavedChanges });
      this.props.onHasUnsavedChangesChanged(hasUnsavedChanges);
    }
  }


  get schema() {
    return [
      {
        key     : 'name',
        renderer: (payer) => (<b>{ payer.name }</b>
        ),
      },
      {
        key     : 'action',
        renderer: (payer) => this.renderAction(payer),
      },
    ];
  }

  get payers() {
    return get(this.props.formValues, 'values.names', []);
  }

  get entities() {
    let entities = this.payers;
    if (this.state.search) {
      entities = filter(entities, (payer) => payer.includes(this.state.search));
    }
    return entities.sort().map((name) => ({
      name,
      action: name,
    }));
  }


  renderAddPayerButton() {
    return (
      <Button
        className="btn--primary btn--filled"
        onClick={() => this.props.onOpenAddPayerModal()}
      >
        <>
          <AddIcon className={styles.header__addButton__icon} />
          <p><FormattedMessage {...messages.buttons.addPayer} /></p>
        </>
      </Button>
    );
  }


  renderSearch() {
    return (
      <div className={styles.header__search}>
        <Input
          placeholder={messages.placeholders.searchPayer}
          value={this.state.search}
          onChange={(input) => this.setState({ search: input.value })}
        />
        <Loupe className={styles.search__loupe} />
      </div>
    );
  }


  renderHeader() {
    return (
      <div className={styles.header}>
        { this.renderSearch() }
        { this.renderAddPayerButton() }
      </div>
    );
  }


  renderAction(payer) {
    return (
      <DeleteIcon
        className={styles.deleteIcon}
        onClick={() => this.onClickDelete(payer.name)}
      />
    );
  }


  renderTableHeaders() {
    return (
      <div className={styles.table__headers}>
        <div>
          <h2 className="text--paragraph"><FormattedMessage {...messages.labels.payerName} /></h2>
          <h2 className="text--paragraph"><FormattedMessage {...messages.labels.action} /></h2>
        </div>
        <div>
          <h2 className="text--paragraph"><FormattedMessage {...messages.labels.payerName} /></h2>
          <h2 className="text--paragraph"><FormattedMessage {...messages.labels.action} /></h2>
        </div>
      </div>
    );
  }


  renderTable() {
    return (
      <>
        { this.renderTableHeaders() }
        <Table
          idKey="name"
          schema={this.schema}
          entities={this.entities}
          perPage={20}
          isPerPageOff
          isHeaderOff
        />
      </>
    );
  }


  renderAddPayerModal() {
    return (
      <AddPayerModal
        isInProgress={this.props.isAddCountryInProgress}
        payers={this.payers}
        languageOptions={this.props.languageOptions}
        hasErrors={this.props.hasAddCountryErrors}
        onAddPayer={(payer) => this.onAddPayer(payer)}
      />
    );
  }


  renderDeletePayerModal() {
    if (!this.state.confirmDeletePayerName) return null;
    return (
      <ConfirmDeletePayerModal
        isInProgress={this.props.isDeleteCountryInProgress}
        hasErrors={this.props.hasDeleteCountryErrors}
        onDeletePayer={() => this.onDeletePayer()}
      />
    );
  }

  render() {
    return (
      <>
        <Form
          onSubmit={() => this.onSubmit()}
          onReset={() => this.onReset()}
        >
          { this.renderHeader() }
          { this.renderTable() }
          <SubmitOrDiscardButtonsContainer
            isSaveEnable={this.state.hasUnsavedChanges}
            isDiscardEnable={this.state.hasUnsavedChanges}
            isInProgress={this.props.isUpdateInProgress || this.props.isFetchInProgress}
            additionalInfoMessage={
              this.props.isConfiguredCountryActive
                ? <p><FormattedMessage {...messages.infos.saveOnActiveCountry} /></p>
                : null
            }
          />
        </Form>
        { this.renderAddPayerModal() }
        { this.renderDeletePayerModal() }
      </>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues               : App.selectors.formSelector(constants.CONFIGURE_COUNTRY_PAYERS_TAB_FORM)(state),
  configurationPayers      : selectors.payers(state),
  isUpdateInProgress       : selectors.isUpdateCountryConfigurationPayersInProgress(state),
  isConfiguredCountryActive: selectors.isConfiguredCountryActive(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.CONFIGURE_COUNTRY_PAYERS_TAB_FORM;
  return {
    onSubmit               : (values) => dispatch(actions.updateCountryConfigurationPayers(values)),
    onSetFormValue         : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues        : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors           : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing       : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm            : () => dispatch(App.actions.clearForm(formName)),
    onOpenAddPayerModal    : () => dispatch(App.actions.openModal(constants.ADD_PAYER_MODAL)),
    onCloseAddPayerModal   : () => dispatch(App.actions.closeModal(constants.ADD_PAYER_MODAL)),
    onOpenDeletePayerModal : () => dispatch(App.actions.openModal(constants.DELETE_PAYER_MODAL)),
    onCloseDeletePayerModal: () => dispatch(App.actions.closeModal(constants.DELETE_PAYER_MODAL)),
  };
};


const ConnectedPayersForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PayersForm);


export default withStyles(styles)(ConnectedPayersForm);
