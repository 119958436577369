import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import withStyles from 'isomorphic-style-loader/withStyles';
import Form from 'components/Form';
import FormContainerAbstract from 'components/FormContainerAbstract';
import SubmitOrDiscardButtonsContainer from 'components/Form/SubmitOrDiscardButtonsContainer';
import App from 'modules/App';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import * as constants from '../../../../constants';
import messages from '../../../../messages';
import { getIdentifierFormKeyByName } from './customizedIdentifierHelper';
import SelectStatusesOptionSection from './SelectStatusesOptionSection';
import styles from './Identifiers.pcss';
import IdentifierTermTable from './IdentifierTermTable';
import UpdateOrAddIdentifierOptionModal
  from './IdentifierTermTable/UpdateOrAddIdentifierOptionModal';


class IdentifiersForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    isFetchInProgress         : PropTypes.bool.isRequired,
    // Explicit actions
    onHasUnsavedChangesChanged: PropTypes.func,
    // Implicit props
    isUpdateInProgress        : PropTypes.bool.isRequired,
    // Implicit actions
    onCloseModal              : PropTypes.func,
    onSetAlert                : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      formInitialState   : JSON.parse(JSON.stringify(this.props.identifiers)),
      hasUnsavedChanges  : false,
      shouldReset        : false,
      addIdentifierOption: null,
    };
    this.props.onSetFormValues(this.props.identifiers);
  }


  get accountIdentifiersSelectList() {
    return ['personalIdentifier', 'licence', 'designation'];
  }

  get clinicPatientSelectList() {
    return ['phone', 'otherInformation'];
  }


  get organizationIdentifiersSelectList() {
    return ['zipCode', 'state', 'organizationIdentifier'];
  }


  get existingTermKeys() {
    const terms = get(this.props.formValues, 'values.terms', []);
    return terms.map((term) => (term.termKey));
  }


  componentDidUpdate(prevProps) {
    this.setFormStatus();
    if (prevProps.isUpdateInProgress && !this.props.isUpdateInProgress) {
      this.onSetShouldReset();
    }
    if (this.state.shouldReset && !this.props.isFetchInProgress) {
      this.onInitialize();
    }
  }


  onSetShouldReset() {
    this.setState({ shouldReset: true });
  }


  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  onValidate(rules) {
    const values = get(this.props.formValues, 'values', {});
    let errors = null;
    forEach(constants.CUSTOMIZED_IDENTIFIERS_KEYS, (customizedIdentifiers) => {
      const identifierKey = getIdentifierFormKeyByName(this.props.formValues, customizedIdentifiers);
      const status = get(values, `${identifierKey}.status`);
      if (status === 'Mandatory' || status === 'Optional') {
        const identifierTermKey = customizedIdentifiers.split('.')[1];
        const terms = get(values, 'terms');
        const hasAnyTerm = find(terms, (term) => {
          const hasValue = term.identifierNames.includes(identifierTermKey);
          return hasValue;
        });
        if (!hasAnyTerm) {
          this.props.onSetAlert({
            type         : 'error',
            message      : messages.errors.businessErrors.ActiveIdentifierRequiresOptions,
            messageValues: {
              tab: <FormattedMessage {...get(messages.identifierTab.headers, customizedIdentifiers)} />,
            },
          });
          errors = {};
        }

      }
    });
    return {
      validatedValues: values,
      errors,
    };
  }


  onInitialize() {
    this.setState(
      {
        formInitialState   : JSON.parse(JSON.stringify(this.props.identifiers)),
        hasUnsavedChanges  : false,
        shouldReset        : false,
        addIdentifierOption: null,
      },
    );
    this.props.onSetFormValues(this.props.identifiers);
  }


  onReset() {
    this.props.onDismissAllAlerts();
    this.props.onSetFormValues(cloneDeep(this.state.formInitialState));
    this.props.onFormErrors(null);
  }


  onSubmitForm() {
    this.props.onDismissAllAlerts();
    this.onSubmit();
  }


  onUpdateOrAddIdentifierOption(addIdentifierOption) {
    this.setState({ addIdentifierOption });
  }


  setFormStatus() {
    const hasUnsavedChanges = !isEqual(this.state.formInitialState, this.props.formValues.values);
    if (this.state.hasUnsavedChanges !== hasUnsavedChanges) {
      this.setState({ hasUnsavedChanges });
      this.props.onHasUnsavedChangesChanged(hasUnsavedChanges);
    }
  }


  renderSelectStatusesSections() {
    return (
      <div className={styles.selectStatuses}>
        <div className={styles.selectStatuses__optionSection}>
          <SelectStatusesOptionSection
            className="mb-7"
            formValues={this.props.formValues}
            sectionName="accountIdentifiers"
            headerMessage={messages.headers.accountIdentifiers}
            statuses={this.accountIdentifiersSelectList}
            onSetFormValue={this.props.onSetFormValue}
          />
          <SelectStatusesOptionSection
            formValues={this.props.formValues}
            sectionName="clinicPatientIdentifiers"
            headerMessage={messages.headers.clinicPatient}
            statuses={this.clinicPatientSelectList}
            onSetFormValue={this.props.onSetFormValue}
          />
        </div>

        <div className={styles.selectStatuses__optionSection}>
          <SelectStatusesOptionSection
            formValues={this.props.formValues}
            sectionName="organizationIdentifiers"
            headerMessage={messages.headers.organizationIdentifiers}
            statuses={this.organizationIdentifiersSelectList}
            onSetFormValue={this.props.onSetFormValue}
          />
        </div>
      </div>
    );
  }


  renderIdentifierTerm() {
    return (
      <IdentifierTermTable
        formValues={this.props.formValues}
        headerMessage={messages.headers.nationalIdentifiersRecords}
        onSetFormValue={this.props.onSetFormValue}
        addIdentifierOption={this.state.addIdentifierOption}
      />
    );
  }


  render() {
    if (!this.props.formValues) return null;
    return (
      <div>
        <Form
          onSubmit={() => this.onSubmitForm()}
          onReset={() => this.onReset()}
        >
          { this.renderSelectStatusesSections() }
          { this.renderIdentifierTerm() }
          <SubmitOrDiscardButtonsContainer
            isSaveEnable={this.state.hasUnsavedChanges}
            isDiscardEnable={this.state.hasUnsavedChanges}
            isInProgress={this.props.isUpdateInProgress || this.props.isFetchInProgress}
            additionalInfoMessage={
              this.props.isConfiguredCountryActive
                ? <p><FormattedMessage {...messages.infos.saveOnActiveCountry} /></p>
                : null
            }
          />
        </Form>
        <UpdateOrAddIdentifierOptionModal
          onSubmit={(item) => this.onUpdateOrAddIdentifierOption(this, item)}
          existingTermKeys={this.existingTermKeys}
        />
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(
    constants.CONFIGURE_COUNTRY__IDENTIFIERS_TAB_FORM,
  )(state),
  identifiers              : selectors.identifiers(state),
  isUpdateInProgress       : selectors.isUpdateCountryConfigurationIdentifiersInProgress(state),
  isConfiguredCountryActive: selectors.isConfiguredCountryActive(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.CONFIGURE_COUNTRY__IDENTIFIERS_TAB_FORM;
  return {
    onSubmit          : (values) => dispatch(actions.updateCountryConfigurationIdentifiers(values)),
    onSetFormValue    : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues   : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors      : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing  : () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm       : () => dispatch(App.actions.clearForm(formName)),
    onSetAlert        : (alert) => dispatch(App.actions.setAlert(alert)),
    onDismissAllAlerts: () => dispatch(App.actions.dismissAllAlerts()),
    onCloseModal      : () => dispatch(App.actions.closeModal()),
  };
};


const ConnectedIdentifiersForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentifiersForm);


export default withStyles(styles)(ConnectedIdentifiersForm);
