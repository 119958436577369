import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import { AppContext } from 'context';
import ChevronRight from 'svg/chevron-right.svg';
import Chip from 'components/Chip';
import Button from 'components/Form/Button';
import Tabs from 'components/Tabs';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import App from 'modules/App';
import Account from 'modules/Account';
import { SCOPE_NAMES } from 'modules/Account/constants';
import * as actions from '../../actions';
import * as shapes from '../../shapes';
import messages from '../../messages';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import EnableCountryModal from './EnableCountryModal';
import UnitsOfMeasurements from './tabs/UnitsOfMeasurements';
import General from './tabs/General';
import GlucoseRanges from './tabs/GlucoseRanges';
import Payers from './tabs/Payers';
import Identifiers from './tabs/Identifiers';
import Meters from './tabs/Meters';
import styles from './ConfigureCountry.pcss';


class ConfigureCountry extends React.Component {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    countryCode                               : PropTypes.string.isRequired,
    hasActiveViewUnsavedChanges               : PropTypes.bool,
    // Explicit actions
    onSetHasActiveViewUnsavedChanges          : PropTypes.func,
    // Implicit props
    country                                   : shapes.country,
    enableCountryErrors                       : PropTypes.array,
    openModalId                               : PropTypes.string,
    isFetchInProgress                         : PropTypes.bool,
    isSendCountryConfigurationAnyTabInProgress: PropTypes.bool,
    isEnableCountryProgress                   : PropTypes.bool,
    isSDNAdmin                                : PropTypes.bool,
    // Implicit actions
    onFetchCountry                            : PropTypes.func,
    onFetchCountryConfiguration               : PropTypes.func,
    onFetchLanguages                          : PropTypes.func,
    onFetchDevices                            : PropTypes.func,
    onOpenUnsavedChangesModal                 : PropTypes.func,
    onOpenEnableCountryModal                  : PropTypes.func,
    onCloseModal                              : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      activeViewKey               : this.viewKeysOrder[0],
      unsavedChangesModalInfoLabel: null,
      isLoaded                    : false,
    };
  }


  componentDidMount() {
    this.props.onFetchCountry(this.props.countryCode);
    this.props.onFetchCountryConfiguration(this.props.countryCode);
    this.props.onFetchLanguages();
    this.props.onFetchDevices();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isSendCountryConfigurationAnyTabInProgress
          && !this.props.isSendCountryConfigurationAnyTabInProgress) {
      this.props.onFetchCountryConfiguration(this.props.countryCode);
      this.handleHasUnsavedChangesChanged(false);
    }
    if (prevProps.isEnableCountryProgress && !this.props.isEnableCountryProgress && !this.props.enableCountryErrors) {

      this.props.onFetchCountry(this.props.countryCode);
    }
    if (prevProps.isFetchInProgress && !this.props.isFetchInProgress) {
      this.onInit();
    }
  }


  onInit() {
    this.setState({ isLoaded: true });
  }


  onTabClick(activeViewKey) {
    if (this.props.hasActiveViewUnsavedChanges) {
      this.setState({ unsavedChangesModalInfoLabel: messages.infos.unsavedConfigurationOnChangeTab });
      this.props.onOpenUnsavedChangesModal();
    } else {
      this.setState({ activeViewKey });
    }
  }


  onBackButtonClick() {
    if (this.props.hasActiveViewUnsavedChanges) {
      this.setState({ unsavedChangesModalInfoLabel: messages.infos.unsavedConfigurationOnClickBack });
      this.props.onOpenUnsavedChangesModal();
    } else {
      this.context.redirect(this.context.getUrl('countries'));
    }
  }


  onCloseModal() {
    this.props.onCloseModal();
    this.setState({ unsavedChangesModalInfoLabel: null });
  }


  onEnableCountry() {
    this.props.onOpenEnableCountryModal();
  }


  get views() {
    return ({
      General,
      UnitsOfMeasurements,
      GlucoseRanges,
      Payers,
      Identifiers,
      Meters,
    });
  }


  get viewKeysOrder() {
    return (['General', 'UnitsOfMeasurements', 'GlucoseRanges', 'Payers', 'Identifiers', 'Meters']);
  }


  get tabsLabels() {
    return this.viewKeysOrder.map((id) => ({ id, message: messages.tabs.configureCountry[camelCase(id)] }))
      .reduce((prev, current) => ({ ...prev, [current.id]: current.message }), {});
  }


  handleHasUnsavedChangesChanged = (status) => {
    if (this.props.hasActiveViewUnsavedChanges !== status) {
      this.props.onSetHasActiveViewUnsavedChanges(status);
    }
  };


  renderSwitchCountryIsActiveButton() {
    const { isActive } = this.props.country;
    const { isSDNAdmin } = this.props;
    if (isActive) {
      return (
        <Chip Chip className="ml-6" labelMessage={messages.labels.countryEnabled} styleModifier="success" />
      );
    }
    return (
      <>
        <Chip className="ml-6" labelMessage={messages.labels.countryDisabled} styleModifier="error" />
        {
          isSDNAdmin
            ? (
              <Button
                type="button"
                styleModifier="primary"
                labelMessage={messages.buttons.enableCountry}
                className={cn('btn mr-0 ml-auto', { 'btn--filled': !isActive })}
                isInProgress={this.props.isEnableCountryProgress}
                isDisabled={this.props.hasActiveViewUnsavedChanges}
                titleMessage={
                  this.props.hasActiveViewUnsavedChanges
                    ? messages.infos.unsavedConfigurationOnChangeTab
                    : null
                }
                onClick={() => this.onEnableCountry()}
              />
            )
            : null
        }
      </>

    );
  }


  renderHeader() {
    return (
      <div className={styles.configureCountry__header}>
        <Button
          type="button"
          onClick={() => this.onBackButtonClick()}
        >
          <ChevronRight className="backArrow" />
          <FormattedMessage
            {...messages.buttons.backToListCountries}
          />
        </Button>
        <div>
          <header className="text--h1"> { this.props.country.englishName }</header>
          { this.renderSwitchCountryIsActiveButton() }
        </div>
      </div>
    );
  }


  renderTabs() {
    return (
      <Tabs
        activeItem={this.state.activeViewKey}
        items={this.viewKeysOrder}
        messages={this.tabsLabels}
        action={(activeViewKey) => this.onTabClick(activeViewKey)}
      />
    );
  }


  renderContent() {
    const ActiveView = this.views[this.state.activeViewKey];
    return (
      <div className="card card--mainContent justify-content-between">
        { this.renderTabs() }
        <ActiveView
          countryCode={this.props.country.code}
          ref={this.myRef}
          onHasUnsavedChangesChanged={this.handleHasUnsavedChangesChanged}
          isFetchInProgress={this.props.isFetchInProgress}
        />
      </div>
    );
  }


  renderModals() {
    if (this.props.openModalId === App.constants.UNSAVED_CHANGES_MODAL) {
      return (
        <UnsavedChangesModal
          headerMessage={App.messages.headers.unsavedConfiguration}
          labelMessage={this.state.unsavedChangesModalInfoLabel || messages.infos.unsavedConfigurationOnChangeTab}
          openModalId={this.props.openModalId}
          onClose={() => this.onCloseModal()}
        />
      );
    }
    if (this.props.openModalId === constants.ENABLE_COUNTRY_MODAL) {
      return (
        <EnableCountryModal
          country={this.props.country}
        />
      );
    }
    return null;
  }


  render() {
    if (!this.state.isLoaded || !this.props.country) {
      return null;
    }
    return (
      <div className={styles.configureCountry}>
        { this.renderHeader() }
        { this.renderContent() }
        { this.renderModals() }
      </div>
    );
  }

}


const mapStateToProps = (state) => (
  {
    country                    : selectors.configuredCountry(state),
    hasActiveViewUnsavedChanges: selectors.hasActiveViewUnsavedChanges(state),
    isFetchInProgress          : selectors.isFetchCountryInProgress(state)
                        || selectors.isFetchCountryConfigurationInProgress(state)
                        || selectors.isFetchLanguagesInProgress(state),
    isSDNAdmin                                : Account.selectors.scope(state) === SCOPE_NAMES.SDN_ADMIN,
    isSendCountryConfigurationAnyTabInProgress: selectors.isSendCountryConfigurationAnyTabInProgress(state),
    isEnableCountryProgress                   : selectors.isEnableCountryProgress(state),
    enableCountryErrors                       : selectors.enableCountryErrors(state),
    openModalId                               : App.selectors.modal(state),
  });

const mapDispatchToProps = (dispatch) => ({
  onSetHasActiveViewUnsavedChanges: (hasChanges) => dispatch(actions.setHasActiveViewUnsavedChanges(hasChanges)),
  onFetchCountry                  : (countryCode) => dispatch(actions.fetchCountry(countryCode)),
  onFetchCountryConfiguration     : (countryCode) => dispatch(actions.fetchCountryConfiguration(countryCode)),
  onFetchLanguages                : (countryCode) => dispatch(actions.fetchLanguages(countryCode)),
  onFetchDevices                  : (countryCode) => dispatch(App.actions.fetchDevices(countryCode)),
  onOpenUnsavedChangesModal       : () => dispatch(App.actions.openModal(App.constants.UNSAVED_CHANGES_MODAL)),
  onOpenEnableCountryModal        : () => dispatch(App.actions.openModal(constants.ENABLE_COUNTRY_MODAL)),
  onCloseModal                    : () => dispatch(App.actions.closeModal()),
});


const ConnectedConfigureCountry = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfigureCountry);


export default withStyles(styles)(ConnectedConfigureCountry);
