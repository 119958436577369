import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';
import ChevronRight from 'svg/chevron-right.svg';
import XIcon from 'svg/x.svg';
import intlShape from 'shapes/intlShape';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Link from 'components/Link';

import messages from './messages';

/**
 * Select component
 * @param {string} id - unique input id
 * @param {string} optionsFrom - list of objects with options for select
 * @param {string} valueKey - key in object from optionsFrom for option value
 * @param {string} labelKey - key in object from optionsFrom for option label
 * @param {string} [toKey] - key in object from optionsFrom for option link
 * @param {string} [value=""] - selected value
 * @param {Object} [noValueMessage] - custom intl message visible if there's no selected value
 * @param {(string|Object)} [classes] - additional input classes
 * @param {(string|Object)} [selectedFieldClasses] - additional classes for a selected value field
 * @param {boolean} [isShort=false] - switch to short variant
 * @param {function} onChange - action that is triggered by select value change
 */
class Select extends React.PureComponent {


  static propTypes = {
    id          : PropTypes.string,
    optionsFrom : PropTypes.array.isRequired,
    featuredFrom: PropTypes.array,
    valueKey    : PropTypes.string.isRequired,
    labelKey    : PropTypes.string.isRequired,
    toKey       : PropTypes.string,
    value       : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ])),
    ]),
    noValueMessage: PropTypes.object,
    className     : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    selectedFieldClasses: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    wrapperClasses: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isDisabled  : PropTypes.bool,
    isShort     : PropTypes.bool,
    onChange    : PropTypes.func.isRequired,
    intl        : intlShape.isRequired,
    hiddenValues: PropTypes.array,
    hasError    : PropTypes.bool,
    isMulti     : PropTypes.bool,
    isVisible   : PropTypes.bool,
  };


  static defaultProps = {
    id          : '',
    value       : '',
    isDisabled  : false,
    isShort     : false,
    hiddenValues: [],
    isMulti     : false,
    isVisible   : true,
  };


  constructor(props) {
    super(props);
    [
      'onSelectChange',
      'onSelectToggle',
      'onInputFocus',
      'onInputBlur',
      'onKeyDown',
    ].forEach((func) => { this[func] = this[func].bind(this); });
    this.state = {
      isDropdownOpen: false,
      value         : props.value,
    };

    this.multiSelectRef = createRef();
    this.fullOptionsFrom = [];
    this.currentFullIdx = 0;
    this.featuredLength = 0;
    this.fullLength = 0;
    this.input = null;
    this.list = null;
    this.options = {};
    this.queryString = '';
    this.clearQuery = null;

    this.onSetOptions();
  }


  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.optionsFrom !== this.props.optionsFrom
      || prevProps.featuredFrom !== this.props.featuredFrom
    ) {
      this.onSetOptions();
    }

    if (
      (prevProps.value !== this.props.value || prevProps.optionsFrom !== this.props.optionsFrom)
      && this.props.value !== this.state.value
      && this.props.optionsFrom.length
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value: this.props.value,
      });
    }

    if (
      prevState.isDropdownOpen !== this.state.isDropdownOpen
      && this.state.isDropdownOpen
      && (this.state.value || this.state.value === 0)
    ) {
      const currentOptionIdx = findIndex(this.fullOptionsFrom, { [this.props.valueKey]: this.state.value });
      if (currentOptionIdx >= 0) {
        this.currentFullIdx = currentOptionIdx;
        this.onScrollToOption(get(this.fullOptionsFrom, [currentOptionIdx, this.props.labelKey]));
      }
    }
  }


  onSetOptions() {
    if (this.props.featuredFrom) {
      this.featuredLength = this.props.featuredFrom.length;
    }
    this.fullOptionsFrom = [
      ...this.props.featuredFrom || [],
      ...this.props.optionsFrom,
    ];
    this.fullLength = this.fullOptionsFrom.length;
  }


  onSelectChange(value) {
    if (this.props.isDisabled) {
      return;
    }
    if (!this.props.isMulti) {
      this.setState(() => ({
        value,
        isDropdownOpen: false,
      }));
    } else {
      value = this.props.value.includes(value)
        ? this.props.value.filter((v) => v !== value)
        : [...this.props.value, value].sort();
    }

    this.props.onChange({
      id: this.props.id,
      value,
    });
  }


  onSelectToggle() {
    if (this.props.isDisabled) {
      return;
    }
    this.setState((prevState) => ({
      isDropdownOpen: !(prevState.isDropdownOpen),
    }));
  }


  onInputFocus(e) {
    if (this.props.isDisabled) {
      return;
    }
    if (this.props.isMulti && e && !e.relatedTarget) {
      this.setState({
        isDropdownOpen: false,
      });
    } else {
      this.setState({
        isDropdownOpen: true,
      });
    }
  }


  onInputBlur(e) {
    if (this.props.isMulti && e && e.relatedTarget && e.relatedTarget.id === this.props.id) {
      this.input.focus();
      this.setState({
        isDropdownOpen: true,
        value         : this.props.value,
      });
      return;
    }
    this.setState({
      isDropdownOpen: false,
      value         : this.props.value,
    });
  }


  onScrollToOption(label) {
    const featured = this.currentFullIdx + 1 <= this.featuredLength;
    const option = get(
      this.options,
      `${label}-${featured ? 'feat' : 'main'}`,
      get(this.options, `${label}-main`),
    );
    if (!isEmpty(option)) {
      this.list.scrollTop = option.offsetTop - (2 * option.offsetHeight);
    }
  }


  onFocusPreviousOption() {
    if (this.currentFullIdx - 1 >= 0) {
      this.currentFullIdx--;
      const label = get(this.fullOptionsFrom, [this.currentFullIdx, this.props.labelKey]);
      this.onScrollToOption(label);
      this.setState({
        value: get(this.fullOptionsFrom, [this.currentFullIdx, this.props.valueKey]),
      });
    }
  }


  onFocusNextOption() {
    if (this.currentFullIdx + 1 <= this.fullLength - 1) {
      this.currentFullIdx++;
      const label = get(this.fullOptionsFrom, [this.currentFullIdx, this.props.labelKey]);
      this.onScrollToOption(label);
      this.setState({
        value: get(this.fullOptionsFrom, [this.currentFullIdx, this.props.valueKey]),
      });
    }
  }


  onKeyDown(event) {
    switch (event.key) {
      case 'Tab':
        return;
      case 'Shift':
      case 'Control':
      case 'Alt':
      case 'AltGraph':
      case 'Backspace':
      case 'Meta':
        break;
      case 'Enter':
        event.preventDefault();
        this.onSelectChange(this.state.value);
        break;
      case 'Escape':
        this.onInputBlur();
        break;
      case 'ArrowUp':
        this.onFocusPreviousOption();
        break;
      case 'ArrowDown':
        this.onFocusNextOption();
        break;
      default: {
        this.currentFullIdx = this.firstEntry(event.key);
        const firstEntry = get(this.fullOptionsFrom, this.currentFullIdx);
        if (firstEntry) {
          this.onScrollToOption(get(firstEntry, this.props.labelKey));
          this.setState({
            value: get(firstEntry, this.props.valueKey),
          });
        }
      }
    }
    event.preventDefault();
  }


  onBadgeClick(e, result) {
    const { optionsFrom, valueKey, isMulti } = this.props;
    e.preventDefault();
    const value = optionsFrom.find((option) => option[valueKey] === result);
    if (!value) {
      return;
    }

    this.onSelectChange(value[valueKey]);
    if (!isMulti) {
      this.onInputBlur();
    }
  }


  get currentLabel() {
    const { isMulti } = this.props;

    const defaultValue = this.props.intl.formatMessage(messages.select.default);

    if ((!this.state.value && this.state.value !== 0) || (isEmpty(this.state.value) && isMulti)) {
      if (this.props.noValueMessage) {
        return this.props.intl.formatMessage(this.props.noValueMessage);
      }
      return defaultValue;
    }
    if (this.state.value === '') {
      return defaultValue;
    }
    if (isMulti) {
      return null;
    }
    return get(
      // eslint-disable-next-line eqeqeq
      find(this.fullOptionsFrom, (option) => option[this.props.valueKey] == this.state.value),
      this.props.labelKey,
      defaultValue,
    );
  }


  firstEntry(key) {
    clearTimeout(this.clearQuery);
    const keyValue = toLower(key);
    this.queryString += keyValue;
    this.clearQuery = setTimeout(
      () => { this.queryString = ''; },
      1000,
    );
    return findIndex(this.fullOptionsFrom, (el) => startsWith(
      toLower(el[this.props.labelKey]),
      this.queryString,
    ));
  }


  renderInput() {
    const { isMulti } = this.props;
    return (
      <input
        className="select__input"
        id={this.props.id}
        onFocus={() => this.onInputFocus()}
        onBlur={(e) => this.onInputBlur(e)}
        onMouseDown={() => (isMulti ? {} : this.onSelectToggle())}
        onKeyDown={this.onKeyDown}
        defaultValue={this.currentLabel}
        ref={(input) => { this.input = input; }}
        autoComplete="off"
        multiple={isMulti}
        readOnly
      />
    );
  }


  renderSelectOption(option, keySuffix = 'main') {
    const value = get(option, this.props.valueKey);
    return (
      <option
        key={`${this.props.id}-${value}-${keySuffix}`}
        value={value}
        className={
          cn(
            { 'd-none': this.props.hiddenValues.includes(value) },
          )
        }
      >
        { get(option, this.props.labelKey, '---') }
      </option>
    );
  }


  renderSelect() {
    let options = [];
    if (this.props.featuredFrom) {
      options = differenceBy(this.props.optionsFrom, this.props.featuredFrom, this.props.valueKey);
    } else {
      options = this.props.optionsFrom;
    }

    return (
      <select
        className="select__native-select"
        value={this.state.value}
        onBlur={() => this.onInputBlur()}
        onChange={(evt) => this.onSelectChange(evt.target.value)}
        multiple={this.props.isMulti}
      >
        { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
        <option className="d-none" />
        {
          this.props.featuredFrom
            ? map(this.props.featuredFrom, (option) => this.renderSelectOption(option, 'feat'))
            : null
        }
        {
          this.props.featuredFrom
            ? <option disabled value="-----" /> // eslint-disable-line jsx-a11y/control-has-associated-label
            : null
        }
        { map(options, (option) => this.renderSelectOption(option)) }
      </select>
    );
  }


  renderMultiSelected() {
    const { isMulti, isVisible, optionsFrom, valueKey } = this.props;
    if (!isMulti) {
      return null;
    }
    const value = this.props.value || [];


    function getLabel(result, labelKey) {
      const item = optionsFrom.find((option) => option[valueKey] === result);
      if (!item) {
        return null;
      }
      return item[labelKey] || item.label;
    }

    let multiSelectWidth;
    if (this.multiSelectRef && this.multiSelectRef.current) {
      multiSelectWidth = this.multiSelectRef.current.clientWidth;
    }


    let fullNameWidthElements = 0;
    function estimateBadgeSize(txt, font) {
      const element = document.createElement('canvas');
      const context = element.getContext('2d');
      context.font = font;
      const tsize = { width: context.measureText(txt).width + 48, height: parseInt(context.font, 10) };
      return tsize;
    }

    let totalWidth = 0;
    value.forEach((result) => {
      const { width } = estimateBadgeSize(getLabel(result, this.props.labelKey), '14px Inter');
      totalWidth += width;
      if (totalWidth < multiSelectWidth - 40) {
        fullNameWidthElements += 1;
      }
    });


    return (
      <div ref={this.multiSelectRef} className="select__multiWrapper">
        {
          isVisible && value.map(
            (result, index) => {
              if (index > fullNameWidthElements) {
                return null;
              }
              if (index < fullNameWidthElements) {
                return (
                  <div
                    className="select__multi__itemWrapper"
                    key={`selected-field-${result}`}
                  >
                    <Link
                      to=""
                      key={`result-id-${result}`}
                      onClick={(e) => this.onBadgeClick(e, result)}
                      className="select__multi__item"
                    >
                      { getLabel(result, this.props.labelKey) }
                      <XIcon />
                    </Link>
                  </div>
                );
              }
              return (
                <div
                  className="select__multi__itemWrapper"
                  key={`rest-selected-field-${value.length - fullNameWidthElements}`}
                >
                  <Link
                    to=""
                    key={`result-id-${result}`}
                    onClick={(e) => e.preventDefault()}
                    className="select__multi__item"
                  >
                    +{ value.length - fullNameWidthElements }
                  </Link>
                </div>
              );

            },
          )
        }
      </div>

    );
  }


  renderOptionMulti(option, keySuffix = 'main') {
    const value = get(option, this.props.valueKey);
    return (
      <li
        key={`${this.props.id}-${value}-${keySuffix}`}
        className={
          cn(
            { 'd-none': this.props.hiddenValues.includes(value) },
            'select__list__item',
            'd-flex align-items-center',
            { 'select__list__item--active': this.state.value === value },
          )
        }
        ref={(el) => { this.options[`${get(option, this.props.labelKey)}-${keySuffix}`] = el; }}
        onMouseDown={() => this.onSelectChange(value)}
      >
        <CheckboxRadio
          inputValue="true"
          value={this.state.value.includes(value) ? 'true' : 'false'}
          onChange={() => {}}
        />
        {
          this.props.toKey
            ? (
              <Link
                to={get(option, this.props.toKey)}
              >
                { get(option, this.props.labelKey, '---') }
              </Link>
            )
            : get(option, this.props.labelKey)
        }
      </li>
    );
  }


  renderOption(option, keySuffix = 'main') {
    const { isMulti } = this.props;
    if (isMulti) {
      return this.renderOptionMulti(option, keySuffix);
    }

    const value = get(option, this.props.valueKey);
    return (
      <li
        key={`${this.props.id}-${value}-${keySuffix}`}
        className={
          cn(
            { 'd-none': this.props.hiddenValues.includes(value) },
            'select__list__item',
            { 'select__list__item--active': this.state.value === value },
          )
        }
        ref={(el) => { this.options[`${get(option, this.props.labelKey)}-${keySuffix}`] = el; }}
        onMouseDown={() => this.onSelectChange(value)}
      >
        {
          this.props.toKey
            ? (
              <Link
                to={get(option, this.props.toKey)}
              >
                { get(option, this.props.labelKey, '---') }
              </Link>
            )
            : get(option, this.props.labelKey)
        }
      </li>
    );
  }


  renderSelectList() {
    if (!this.state.isDropdownOpen) {
      return null;
    }
    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className={cn('select__list-wrapper', this.props.wrapperClasses)}
      >
        <ul
          className={
            cn(
              'select__list',
              { 'select__list--short': this.props.isShort },
              { 'select__list--opened': this.state.isDropdownOpen },
            )
          }
          ref={(list) => { this.list = list; }}
        >
          {
            this.props.featuredFrom
              ? map(this.props.featuredFrom, (option) => this.renderOption(option, 'feat'))
              : null
          }
          {
            this.props.featuredFrom
              ? <li className="select__list__divider" />
              : null
          }
          { map(this.props.optionsFrom, (option) => this.renderOption(option)) }
        </ul>
      </motion.div>
    );
  }


  render() {
    const { isMulti } = this.props;
    return (
      <div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={isMulti ? '0' : undefined}
        id={isMulti ? this.props.id : undefined}
        className={
          cn(
            'select__selected-field-wrapper',
            this.props.className,
            {
              'select__selected-field-wrapper--short' : this.props.isShort,
              'select__selected-field-wrapper--opened': this.state.isDropdownOpen,
            },
          )
        }
      >
        <div
          className={
            cn(
              'select__selected-field',
              this.props.selectedFieldClasses,
              {
                'select__selected-field--no-value': !this.props.value && this.props.value !== 0,
                disabled                          : this.props.isDisabled,
                'select__selected-field--opened'  : this.state.isDropdownOpen,
                'border-danger'                   : this.props.hasError,
              },
            )
          }
        >
          { this.currentLabel }
          { this.renderMultiSelected() }
          { this.renderInput() }
          { this.renderSelect() }
        </div>
        <ChevronRight className="select__arrow" />
        <AnimatePresence>
          { this.renderSelectList() }
        </AnimatePresence>
      </div>
    );
  }

}

export default injectIntl(Select);
