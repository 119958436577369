import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as constants from './constants';
import * as actionTypes from './actionTypes';


/**
 * Direct selector to the domain's state
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;


export const countries = createSelector(
  mainSelector,
  (state): Country[] => state.countries,
);


export const allCountries = createSelector(
  mainSelector,
  (state): Country[] => state.allCountries,
);

export const languages = createSelector(
  mainSelector,
  (state) => state.languages,
);


export const isFetchLanguagesInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_LANGUAGES),
);


export const isFetchCountriesInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_COUNTRIES),
);


export const isFetchAllCountriesProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_ALL_COUNTRIES),
);

export const isAddCountryInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ADD_COUNTRY),
);


export const isEnableCountryProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ENABLE_COUNTRY),
);


export const enableCountryErrors = createSelector(
  mainSelector,
  (state) => state.enableCountryErrors,
);


export const hasAddCountryErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ADD_COUNTRY),
);


export const isDeleteCountryProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.DELETE_COUNTRY),
);


export const hasDeleteCountryErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ADD_COUNTRY),
);


export const isDeleteCountryResultSuccess = createSelector(
  mainSelector,
  (state) => state.isDeleteCountryResultSuccess,
);


export const configuredCountry = createSelector(
  mainSelector,
  (state) => state.configuredCountry,
);


export const hasActiveViewUnsavedChanges = createSelector(
  mainSelector,
  (state) => state.hasActiveViewUnsavedChanges,
);


export const configuredCountryCode = createSelector(
  mainSelector,
  (state) => state.configuredCountry && state.configuredCountry.code,
);


export const isConfiguredCountryActive = createSelector(
  mainSelector,
  (state) => state.configuredCountry && state.configuredCountry.isActive,
);


export const isFetchCountryInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_COUNTRY),
);


export const generalSettings = createSelector(
  mainSelector,
  (state) => state.countryConfiguration && state.countryConfiguration.generalSettings,
);


export const unitMeasurements = createSelector(
  mainSelector,
  (state) => state.countryConfiguration && state.countryConfiguration.unitMeasurements,
);

export const glucoseRanges = createSelector(
  mainSelector,
  (state) => state.countryConfiguration && state.countryConfiguration.glucoseRanges,
);


export const payers = createSelector(
  mainSelector,
  (state) => state.countryConfiguration && state.countryConfiguration.payers,
);


export const devices = createSelector(
  mainSelector,
  (state) => state.countryConfiguration && state.countryConfiguration.devices,
);

export const identifiers = createSelector(
  mainSelector,
  (state) => state.countryConfiguration && state.countryConfiguration.identifiers,
);

export const isFetchCountryConfigurationInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_COUNTRY_CONFIGURATION),
);

export const identifierOptionFormValueInitialValue = createSelector(
  mainSelector,
  (state) => state.identifierOptionFormValueInitialValue,
);

export const updateOrAddIdentifierTerm = createSelector(
  mainSelector,
  (state) => state.updateOrAddIdentifierTerm,
);

export const isUpdateCountryConfigurationGeneralInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL),
);

export const isUpdateCountryConfigurationUnitOfMeasurementInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS),
);

export const isUpdateCountryConfigurationGlucoseRangesInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES),
);

export const isUpdateCountryConfigurationPayersInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS),
);

export const isUpdateCountryConfigurationIdentifiersInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS),
);

export const isUpdateCountryConfigurationMetersInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS),
);


export const isSendCountryConfigurationAnyTabInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL)
    || includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS)
    || includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES)
    || includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS)
    || includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS)
    || includes(state, actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS),
);
