import find from 'lodash/find';
import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';

import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


const initialState = {
  countries                            : [],
  allCountries                         : [],
  languages                            : [],
  fetching                             : [],
  sending                              : [],
  errors                               : [],
  hasActiveViewUnsavedChanges          : false,
  enableCountryErrors                  : null,
  isDeleteCountryResultSuccess         : null,
  configuredCountry                    : null,
  countryConfiguration                 : null,
  identifierOptionFormValueInitialValue: null,
  updateOrAddIdentifierTerm            : null,
};


export default function reducer(state = { ...initialState }, action = {}) {

  switch (action.type) {

    case actionTypes.FETCH_COUNTRIES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_COUNTRIES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_COUNTRIES),
      };
    }
    case actionTypes.FETCH_COUNTRIES_SUCCESS: {
      const { countries } = action.payload;
      return {
        ...state,
        countries,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRIES),
      };
    }
    case actionTypes.FETCH_COUNTRIES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRIES),
        errors  : registerAction(state.errors, actionTypes.FETCH_COUNTRIES),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_ALL_COUNTRIES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_ALL_COUNTRIES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_ALL_COUNTRIES),
      };
    }
    case actionTypes.FETCH_ALL_COUNTRIES_SUCCESS: {
      const { countries } = action.payload;
      return {
        ...state,
        allCountries: countries,
        fetching    : unregisterAction(state.fetching, actionTypes.FETCH_ALL_COUNTRIES),
      };
    }
    case actionTypes.FETCH_ALL_COUNTRIES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ALL_COUNTRIES),
        errors  : registerAction(state.errors, actionTypes.FETCH_ALL_COUNTRIES),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_LANGUAGES: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_LANGUAGES),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_LANGUAGES),
      };
    }
    case actionTypes.FETCH_LANGUAGES_SUCCESS: {
      const { languages } = action.payload;
      return {
        ...state,
        languages,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LANGUAGES),
      };
    }
    case actionTypes.FETCH_LANGUAGES_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LANGUAGES),
        errors  : registerAction(state.errors, actionTypes.FETCH_LANGUAGES),
      };
    }

    case actionTypes.SET_HAS_ACTIVE_VIEW_UNSAVED_CHANGES: {
      const { hasActiveViewUnsavedChanges } = action.payload;
      return {
        ...state,
        hasActiveViewUnsavedChanges,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LANGUAGES),
        errors  : registerAction(state.errors, actionTypes.FETCH_LANGUAGES),
      };
    }


    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ADD_COUNTRY: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ADD_COUNTRY),
        errors : unregisterAction(state.errors, actionTypes.ADD_COUNTRY),
      };
    }
    case actionTypes.ADD_COUNTRY_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_COUNTRY),
      };
    }
    case actionTypes.ADD_COUNTRY_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_COUNTRY),
        errors : registerAction(state.errors, actionTypes.ADD_COUNTRY),
      };
    }
    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ENABLE_COUNTRY: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ENABLE_COUNTRY),
        errors : unregisterAction(state.errors, actionTypes.ENABLE_COUNTRY),
      };
    }
    case actionTypes.ENABLE_COUNTRY_SUCCESS: {
      return {
        ...state,
        enableCountryErrors: null,
        sending            : unregisterAction(state.sending, actionTypes.ENABLE_COUNTRY),
      };
    }
    case actionTypes.ENABLE_COUNTRY_ERROR: {
      let enableCountryErrors;
      if (action.payload && action.payload.errors) {
        const { errors } = action.payload;
        enableCountryErrors = [];
        const errorsKeys = Object.keys(errors);
        Object.entries(constants.ENABLE_COUNTRY_ERRORS).forEach(([key, value]) => {
          if (errors[value] || find(errorsKeys, (x) => x.startsWith(value))) {
            enableCountryErrors.push(key);
          }
        });
      }

      return {
        ...state,
        enableCountryErrors,
        sending: unregisterAction(state.sending, actionTypes.ENABLE_COUNTRY),
        errors : registerAction(state.errors, actionTypes.ENABLE_COUNTRY),
      };
    }
    case actionTypes.ENABLE_COUNTRY_CLEAR: {
      return {
        ...state,
        enableCountryErrors: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DELETE_COUNTRY: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.DELETE_COUNTRY),
        errors : unregisterAction(state.errors, actionTypes.DELETE_COUNTRY),
      };
    }
    case actionTypes.DELETE_COUNTRY_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.DELETE_COUNTRY),
      };
    }
    case actionTypes.DELETE_COUNTRY_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.DELETE_COUNTRY),
        errors : registerAction(state.errors, actionTypes.DELETE_COUNTRY),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_COUNTRY: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_COUNTRY),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_COUNTRY),
      };
    }
    case actionTypes.FETCH_COUNTRY_SUCCESS: {
      const { configuredCountry } = action.payload;
      return {
        ...state,
        configuredCountry,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY),
      };
    }
    case actionTypes.FETCH_COUNTRY_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY),
        errors  : registerAction(state.errors, actionTypes.FETCH_COUNTRY),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_COUNTRY_CONFIGURATION: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_COUNTRY_CONFIGURATION),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_COUNTRY_CONFIGURATION),
      };
    }
    case actionTypes.FETCH_COUNTRY_CONFIGURATION_SUCCESS: {
      const { countryConfiguration } = action.payload;
      return {
        ...state,
        countryConfiguration,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY_CONFIGURATION),
      };
    }
    case actionTypes.FETCH_COUNTRY_CONFIGURATION_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_COUNTRY_CONFIGURATION),
        errors  : registerAction(state.errors, actionTypes.FETCH_COUNTRY_CONFIGURATION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_IDENTIFIER_OPTION_FORM_VALUE: {
      const { initialFormValue } = action.payload;
      return {
        ...state,
        identifierOptionFormValueInitialValue: initialFormValue,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_UPDATE_OR_ADD_IDENTIFIER_TERM: {
      const { term } = action.payload;
      return {
        ...state,
        updateOrAddIdentifierTerm: term,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL),
        errors : registerAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_SUCCESS: {
      const { unitMeasurements } = action.payload;
      return {
        ...state,
        unitMeasurements,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS),
        errors : registerAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES: {
      const { glucoseRanges } = action.payload;
      return {
        ...state,
        glucoseRanges,
        sending: registerAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES),
        errors : registerAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS: {
      const { payers } = action.payload;
      return {
        ...state,
        payers,
        sending: registerAction(state.fetching, actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.fetching, actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.fetching, actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS),
        errors : registerAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS: {
      const { identifiers } = action.payload;
      return {
        ...state,
        identifiers,
        sending: registerAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS),
        errors : registerAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS: {
      const { devices } = action.payload;
      return {
        ...state,
        devices,
        sending: registerAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS),
      };
    }
    case actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS),
        errors : registerAction(state.errors, actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default: {
      return state;
    }

  }
}
